var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"current"},[_c('div',{staticClass:"subtitle"},[_vm._v(". /current_job")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" "+_vm._s(_vm.skill)+": ")]),_vm._m(5)]),_vm._m(6)]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" --- actual_cpu_program: ")]),_c('div',{staticClass:"value"},[_c('a',[_vm._v("bweez")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" typeof: ")]),_c('div',{staticClass:"value"},[_vm._v("retail design agency")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" -ctime: ")]),_c('div',{staticClass:"value"},[_vm._v("5years")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" href: ")]),_c('div',{staticClass:"value"},[_c('a',[_vm._v("www.bweez.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" CPU_usage: ")]),_c('div',{staticClass:"value"},[_c('a',[_vm._v("D3V3L0PM3NT")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"value"},[_vm._v("HTML, "),_c('a',[_vm._v("CSS")]),_vm._v(", "),_c('a',[_vm._v("J4V4SCR1PT")]),_vm._v(" with "),_c('a',[_vm._v("Node.js")]),_vm._v(" and "),_c('a',[_vm._v("Vue.js")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" other: ")]),_c('div',{staticClass:"value"},[_vm._v("electronics knowlegdes for "),_c('a',[_vm._v("arduino")]),_vm._v("/"),_c('a',[_vm._v("raspberry")]),_vm._v("/..others")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"current"},[_c('div',{staticClass:"subtitle"},[_vm._v(". /hobbies")]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" programming: ")]),_c('div',{staticClass:"value"},[_c('a',[_vm._v("Analog video")]),_vm._v(" output assisted by "),_c('a',[_vm._v("digital technologies")])])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" analog_conf: ")]),_c('div',{staticClass:"value"},[_vm._v("Cathodes Rays screens from "),_c('a',[_vm._v("oscilloscopes")]),_vm._v(" and "),_c('a',[_vm._v("minitel")]),_vm._v(" filmed by CCTV")])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" : ")]),_c('div',{staticClass:"value"},[_vm._v("video_mixages_table with my lovely "),_c('a',[_vm._v("Panasonic MX-50")]),_vm._v(", "),_c('a',[_vm._v("Tektronix 5110")]),_vm._v(", "),_c('a',[_vm._v("5440")]),_vm._v(" and modules")])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" computing: ")]),_c('div',{staticClass:"value"},[_vm._v("Processing, "),_c('a',[_vm._v("P5.js")]),_vm._v(", M4L, pure_data and many more")])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" Usage: ")]),_c('div',{staticClass:"value"},[_c('a',[_vm._v("VJing")]),_vm._v(" for music live performances")])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"}),_c('div',{staticClass:"value"},[_c('a',[_vm._v("Generative Art")]),_vm._v(" performances")])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"}),_c('div',{staticClass:"value"},[_c('a',[_vm._v("retro engieneering")]),_vm._v(" on "),_c('a',[_vm._v("obselete devices")]),_vm._v(" (70'/80'/90')")])]),_c('div',{staticClass:"agency"},[_c('div',{staticClass:"param"},[_vm._v(" no_categories: ")]),_c('div',{staticClass:"value"},[_vm._v("music, long range kayaking, bike expeditions, RV roadtrips, ..")])])])
}]

export { render, staticRenderFns }