<template>
  <div class="">
    <div class="current">
        <div class="subtitle">. /current_job</div>
        <div class="agency">
            <div class="param">
                 --- actual_cpu_program:
            </div>
           <div class="value"><a>bweez</a></div>
        </div>
            <div class="agency">
            <div class="param">
                 typeof:
            </div>
           <div class="value">retail design agency</div>
        </div>
        <div class="agency">
            <div class="param">
                 -ctime:
            </div>
           <div class="value">5years</div>
        </div>
        <div class="agency">
            <div class="param">
                 href:
            </div>
           <div class="value"><a>www.bweez.com</a></div>
        </div>
        <div class="agency">
            <div class="param">
                 CPU_usage:
            </div>
           <div class="value"><a>D3V3L0PM3NT</a></div>
        </div>
            <div class="agency">
            <div class="param">
                 {{ skill }}:
            </div>
           <div class="value">HTML, <a>CSS</a>, <a>J4V4SCR1PT</a> with <a>Node.js</a> and <a>Vue.js</a></div>
        </div>
                        <div class="agency">
            <div class="param">
                 other:
            </div>
           <div class="value">electronics knowlegdes for <a>arduino</a>/<a>raspberry</a>/..others</div>
        </div>
    </div>
        <div class="current">
        <div class="subtitle">. /hobbies</div>
        <div class="agency">
            <div class="param">
                 programming:
            </div>
           <div class="value"><a>Analog video</a> output assisted by <a>digital technologies</a></div>
        </div>
            <div class="agency">
            <div class="param">
                 analog_conf:
            </div>
           <div class="value">Cathodes Rays screens from <a>oscilloscopes</a> and <a>minitel</a> filmed by CCTV</div>
        </div>
        <div class="agency">
            <div class="param">
                 :
            </div>
           <div class="value">video_mixages_table with my lovely <a>Panasonic MX-50</a>, <a>Tektronix 5110</a>, <a>5440</a> and modules</div>
        </div>
        <div class="agency">
            <div class="param">
                 computing:
            </div>
           <div class="value">Processing, <a>P5.js</a>, M4L, pure_data and many more</div>
        </div>
        <div class="agency">
            <div class="param">
                 Usage:
            </div>
           <div class="value"><a>VJing</a> for music live performances</div>
        </div>
            <div class="agency">
            <div class="param">
                 
            </div>
           <div class="value"><a>Generative Art</a> performances</div>
        </div>
                    <div class="agency">
            <div class="param">
                 
            </div>
           <div class="value"><a>retro engieneering</a> on <a>obselete devices</a> (70'/80'/90')</div>
        </div>
                        <div class="agency">
            <div class="param">
                 no_categories:
            </div>
           <div class="value">music, long range kayaking, bike expeditions, RV roadtrips, ..</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
    return {
        skill: '</>',
    };
},
}
</script>

<style lang='scss'>
.current{
    border: 1px dotted white;
    font-size: 0.5rem;
    line-height: 0.7rem;
    width: 90%;
    margin: 0 auto;
    margin-bottom:2rem;
    .subtitle{
        color: white;
        left: 0;
        text-align: start;
    }
    .agency{
        display: flex;
        margin: 0.5rem;
        .param{
width: 40%;
text-align:right;
        }
        .value{
            padding-left:1rem;
            text-align: left;
            width: 60%;
            a{
                color:yellow
            }
        }
    }
}
</style>